<template>
  <base-section
    id="contactus"
  >
    <v-container>
          <h2 class="font-weight-bold mb-3 text-uppercase text-center">Contact US</h2>

          <v-responsive
            class="mx-auto mb-12"
            width="56"
          >
            <v-divider class="mb-1"></v-divider>

            <v-divider></v-divider>
          </v-responsive>

          <v-row>
            <v-col
              v-for="(feature, i) in contactInfo"
              :key="i"
              cols="12"
              md="4"
            >
              <base-avatar-card
                v-bind="feature"
                align="center"
                horizontal
              >
              </base-avatar-card>
            </v-col>
          </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionContactus',

    data: () => ({
      contactInfo: [
        {
          title: '전화번호',
          icon: 'mdi-phone-dial-outline',
          text: '032) 683-7711',
        },
        {
          title: 'Email',
          icon: 'mdi-email',
          text: 'namkang@nkcc.co.kr',
        },
        {
          title: 'Fax',
          icon: 'mdi-fax',
          text: '032) 683-7713',

        },
      ],
    }),
  }
</script>
